<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Copiar funcionários</h4>
            <button type="button" class="close" data-dismiss="modal" @click="$emit('fechar')">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="">Código do treinamento</label>
                  <input type="text" v-model="codigoTreinamento" class="form-control" placeholder="" aria-describedby="helpId">
                  <small id="helpId" class="text-muted">Informe o código do treinamento a serem copiado os treinandos</small>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn btn-primary" @click="buscar">Buscar treinamento</button>
              </div>
            </div>
            <div v-if="treinamento" class="row mt-3">
              <div class="col-12">
                <div class="form-group">
                  <label for="">Treinamento</label>
                  <input type="text" disabled v-model="treinamento.treinamento_modelo.nome_atividade" class="form-control" placeholder="" aria-describedby="helpId">
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn btn-primary" @click="copiar">Copiar</button>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
         
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import treinamentoRepository from './../../services/api/treinamentoRepository'
export default {
  components: {
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  created () {
    this.idDestino = this.id
  },
  data () {
    return {
      codigoTreinamento: '',
      treinamento: null,
      idDestino: 0
    }
  },
  methods: {
    buscar() {
      treinamentoRepository.buscaTreinamentoPorCodigo(this.codigoTreinamento).then(response => {
        if(response.data.success) {
          this.treinamento = response.data.data
          console.log(this.treinamento)
        }
      })
    },
    copiar() {
      treinamentoRepository.duplicarFuncionariosTreinamento(this.treinamento.id, this.idDestino).then(response => {
        console.log(response)
        this.$swal({
          icon: 'success',
          text: 'Treinandos copiados com sucesso!'
        }).then(() => {
          this.$router.go()
        })
      }).catch(() => {
        this.$swal({
          icon: 'error',
          text: 'Não foi possível realizar a cópia de treinandos!'
        })
      })
    }
  }
}
</script>

<style>
</style>