import Repository from './repository'

const resource = '/v1/funcionarios'

export default {
  buscaFuncionario (idFuncionario) {
    return Repository.get(`${resource}/${idFuncionario}`)
  },
  buscaFuncionariosPorEmpresa (idEmpresa) {
    return Repository.get(`${resource}/empresa/${idEmpresa}`)
  },
  listarFuncionarios (search) {
    return !search ? Repository.get(`${resource}`) : Repository.get(`${resource}/busca/${search}`)
    //return Repository.get(`${resource}`)
  },
  listarFuncionariosFull () {
    return Repository.get(`${resource}/busca/`)
    //return Repository.get(`${resource}`)
  },
  salvarFuncionario (funcionario) {
    return Repository.post(`${resource}`, funcionario)
  },
  atualizarFuncionario (idFuncionario, funcionario) {
    return Repository.put(`${resource}/${idFuncionario}`, funcionario)
  },
  deletarFuncionario (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  verificarCpf(cpf) {
    return Repository.get(`${resource}/verificacpf/${cpf}`)
  }
}
