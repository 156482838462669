import Repository from './repository'

const resource = '/v1/configuracoes/cargos'

export default {
  buscaCargo (idCargo) {
    return Repository.get(`${resource}/${idCargo}`)
  },
  listarCargos () {
    return Repository.get(`${resource}`)
  },
  salvarCargo (empresa) {
    return Repository.post(`${resource}`, empresa)
  },
  atualizarCargo (idEmpresa, empresa) {
    return Repository.put(`${resource}/${idEmpresa}`, empresa)
  },
  deletarCargo (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
