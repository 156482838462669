<template>
  <div class="container-fluid">
    <TreinamentosFuncionariosCopiarModal v-if="modalCopiar" :id="$route.query.treinamento" @fechar="modalCopiar = false" />
    <div class="card">
      <div class="card-header">
        <h4 v-if="treinamento.treinamento_modelo">{{treinamento.treinamento_modelo.nome_atividade}}</h4>
        <span v-if="treinamento.data_inicio">{{treinamento.data_inicio | maskdateptbr}} à {{treinamento.data_fim | maskdateptbr}}</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3 text-right">
            <button type="button" class="btn btn-primary" @click="modalCopiar = true">Copiar funcionários</button>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="funcionarios">Vincular funcionários à empresa:</label>
              <select2 
                :ClearAfterSelectedProp="false"
                :ItemsProp="empresas"
                :FieldProp="'nome_fantasia'"
                :PlaceholderTextProp="'Pesquisar empresa'"
                @Selected="EmpresaSelected">
                <template #item="{item}">
                  {{item.nome_fantasia}} <span class="font-weight-normal">(matriz: {{item.matriz.nome_fantasia}})</span>
                </template>
              </select2>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="funcionarios">Funcionários</label>
              <select2 
                :ClearAfterSelectedProp="true"
                :ItemsProp="filteredFuncionarios"
                :FieldProp="'nome_cpf'"
                :PlaceholderTextProp="'Pesquisar funcionário'"
                @searchText="filteredData"
                @Selected="FuncionarioSelecionado">
              </select2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <table class="table table-hover border table-striped">
              <thead>
                <tr>
                  <th>FUNCIONÁRIO</th>
                  <th>EMPRESA</th>
                  <th>CARGO</th>
                  <th class="text-center">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in funcionariosTreinamento" :key="index">
                  <td>{{item.funcionario.nome}}</td>
                  <td>
                    <select2 
                    :ItemsProp="empresas"
                    :FieldProp="'nome_fantasia'"
                    :PlaceholderTextProp="'Pesquisar empresa'"
                    :ClearAfterSelectedProp="false"
                    :SelectedItemProp="item.empresa"
                    @Selected="EmpresaSelecionada($event, index)">
                      <template #item="{item}">
                        {{item.nome_fantasia}} <span class="font-weight-normal">(matriz: {{item.matriz.nome_fantasia}})</span>
                      </template>
                    </select2>
                  </td>
                  <td>
                    <select2 
                    :ItemsProp="cargos"
                    :FieldProp="'nome'"
                    :PlaceholderTextProp="'Pesquisar cargo'"
                    :ClearAfterSelectedProp="false"
                    :SelectedItemProp="item.cargo"
                    :CreateItemProp="true"
                    @CreatingItem="CadastrarCargo($event, index)"
                    @Selected="CargoSelecionado($event, index)">
                    
                    </select2>
                  </td>
                  <td class="text-center"><i class="fas fa-trash-alt btn btn-danger" title="Deletar funcionário" @click="deletarItem(index)"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
            <button type="button" class="btn btn-primary float-right" @click="salvarFuncionarios">SALVAR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import select2 from './../../uicomponents/select2'
import empresaRepository from './../../services/api/empresaRepository'
import treinamentoRepository from './../../services/api/treinamentoRepository'
import funcionariosRepository from './../../services/api/funcionariosRepository'
import funcionariosCargosRepository from './../../services/api/funcionariosCargosRepository'
import TreinamentosFuncionariosCopiarModal from './TreinamentosFuncionariosCopiarModal'
export default {
  components: {
    select2,
    TreinamentosFuncionariosCopiarModal
  },
  data () {
    return {
      filteredFuncionarios: [],
      modalCopiar: false,
      treinamento: {},
      empresa: null,
      empresas: [],
      funcionarios: [],
      funcionariosTreinamento: [],
      cargos: [],
      idCopiar: 0
    }
  },
  created () {
    if (this.$route.query.treinamento) {
      this.fetch(this.$route.query.treinamento)
    }
    this.ListarEmpresas()
    this.ListarFuncionarios()
    this.ListarCargos()
  },
  methods: {
    filteredData (searchText) {
      let filterKey = searchText && searchText.length > 0 && searchText.toLowerCase()
      //console.log(this.funcionarios)
      let data = [...this.funcionarios]
      
      if (filterKey) {
        data = data.filter(function (row) {
          if(String(row.cpf).indexOf(filterKey) > -1 || row.nome.toLowerCase().indexOf(filterKey) > -1 || row.nome_cpf.toLowerCase().indexOf(filterKey) > -1) {
            return row
          }
        })
      }
      this.filteredFuncionarios = [...data]
      //console.log(this.filteredFuncionarios)
      if(this.filteredFuncionarios.length == 0) {
        this.ListarFuncionarios(filterKey)
      }
      //this.buscaTreinamentoLength = searchText.length
    },
    fetch (idTreinamento) {
      treinamentoRepository.buscaTreinamentoPorId(idTreinamento).then(response => {
        if(response.data['success']) {
          this.treinamento = response.data['data']
        }
      })
      treinamentoRepository.buscaTreinamentoFuncionariosPorTreinamentoId(idTreinamento).then(response => {
        if(response.data['success']) {
          this.funcionariosTreinamento = response.data['data']
        }
      })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Treinamentos' })
    },
    async ListarCargos() {
      let response = await funcionariosCargosRepository.listarCargos()
      if(response.data['success']) {
        this.cargos = response.data['data']
      }
    },
    async CadastrarCargo(nomeCargo, index) {
      let cargo = {
        nome: nomeCargo
      }
      let response = await funcionariosCargosRepository.salvarCargo(cargo)
      if (response.data['success']) {
        this.$swal({
          icon: 'success',
          text: 'Cargo cadastrado com sucesso!'
        }).then( async () => {
          await this.ListarCargos()
          let cargoCriado = this.cargos.find(x => x.nome.toLowerCase() == nomeCargo.toLowerCase())
          this.CargoSelecionado(cargoCriado, index)
        })
      } else {
        this.$swal({
          icon: 'error',
          text: 'Não foi possível realizar o cadastro!'
        })
      }
    },
    ListarEmpresas () {
      empresaRepository.listarUnidades().then(response => {
        if(response.data['success']) {
          this.empresas = response.data['data']
        }
      })
    },
    ListarFuncionarios (search = null) {
      funcionariosRepository.listarFuncionarios(search).then(response => {
        if(response.data['success']) {
          this.filteredFuncionarios = this.funcionarios = response.data['data'].map(obj => {
            let cpf = obj.cpf !== null ? obj.cpf : 'CPF não cadastrado'
            obj['nome_cpf'] = `${obj.nome} (${cpf})`
            return obj
          })
        }
      })
    },
    CargoSelecionado(cargo, index) {
      this.funcionariosTreinamento[index].cargo = cargo == null ? null : cargo
    },
    EmpresaSelecionada (empresa, index) {
      this.funcionariosTreinamento[index].empresa = empresa == null ? null : empresa
    },
    EmpresaSelected (empresa) {
      this.empresa = empresa
    },
    FuncionarioSelecionado (funcionario) {
      if(!funcionario) return
      //console.log(parseInt(funcionario.id))
      let localizaFuncionario = this.funcionariosTreinamento.some(x => parseInt(x.funcionario_id) === parseInt(funcionario.id))
      //console.log(this.funcionariosTreinamento)
      if (!localizaFuncionario) {
        let funcionarioTreinamento = {
          funcionario: funcionario,
          funcionario_id: parseInt(funcionario.id),
          cargo: null,
          empresa: this.empresa ? this.empresa : null,
          treinamento_id: this.treinamento.id
        }
        this.funcionariosTreinamento.push(funcionarioTreinamento)
      }
    },
    salvarFuncionarios () {
      if(!this.validarFuncionarios()) {
        this.$swal({
          icon: 'error',
          text: 'Favor vincular empresa e cargo para todos funcionários!'
        })
      } else {
        let funcionarios = this.funcionariosTreinamento.map(obj => {
          return {
            id: obj.id ? obj.id : null,
            funcionario_id: obj.funcionario.id,
            treinamento_id: obj.treinamento_id,
            empresa_id: obj.empresa.id,
            cargo_id: obj.cargo.id
          }
        })
        treinamentoRepository.salvarTreinamentoFuncionarios(funcionarios).then(response => {
          if(response.data['success']) {
            this.$swal({
              icon: 'success',
              title: 'Treinamento',
              text: 'Funcionários vinculado com sucesso!',
            }).then( () => {
              this.goBack()
            })
          }
        })
      }
    },
    validarFuncionarios() {
      let validado = true
      this.funcionariosTreinamento.map(obj => {
        if (obj.cargo == null || obj.empresa == null) {
          validado = false
        }
      })
      return validado
    },
    deletarItem (index) {
      this.funcionariosTreinamento.splice(index, 1)
    }
  }
}
</script>

<style>

</style>